<template>
  <div>
    <v-breadcrumbs class="py-2" :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <router-link :to="item.href" class="v-breadcrumbs__item">
          {{ item.text }}
        </router-link>
      </template>
    </v-breadcrumbs>
    <v-card outlined class="mt-1">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span class="text-truncate">
          {{ $t("Publisher: ") + record.publishing_name }}
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row v-if="record._id" class="mt-3" dense>
          <v-col cols="12" sm="4" md="3" lg="2">
            <v-img :src="record.photo" contain height="150px" />
          </v-col>
          <v-col cols="12" sm="8" md="9" lg="10">
            <v-row dense>
              <v-col cols="12">
                <div class="text-h3">
                  {{ record.publishing_name }}
                </div>
              </v-col>
              <v-col cols="12" class="d-flex">
                <div>{{ $t("Publishing names: ") }}</div>
                <div v-for="ins in record.publishing_names" :key="ins" class="ml-2">
                  <v-chip class="text-h4" small label>{{ ins }}</v-chip>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div>{{ $t("Current Institution") }}</div>
                <div class="text-h4">
                  {{ record.affiliations.current_institution.name }}
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div>{{ $t("Departament") }}</div>
                <div class="text-h4">
                  {{ record.affiliations.current_institution.departament }}
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div>{{ $t("Scopus ID") }}</div>
                <div class="text-h4">{{ record.ids.scopus_id }}</div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div>{{ $t("ORCiD") }}</div>
                <div class="text-h4">{{ record.ids.orcid }}</div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div>{{ $t("EID") }}</div>
                <div class="text-h4">{{ record.ids.eid }}</div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div>{{ $t("Publications") }}</div>
                <div class="text-h3">
                  {{ record.publications.count }}
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div>{{ $t("Citations") }}</div>
                <div class="text-h3">
                  {{ record.citations.count }}
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div>{{ $t("Cited by") }}</div>
                <div class="text-h3">
                  {{ record.citations.cited_by_count }}
                </div>
              </v-col>
              <!-- <v-col cols="12">
                      <div>{{ $t("Past Institutions") }}</div>
                      <div v-for="ins in record.affiliations.institutions" :key="ins.id" class="text-h4">
                        {{ ins.name }}
                      </div>
                    </v-col> -->
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-tabs>
              <v-tab>{{ $t("Publications") }}</v-tab>
              <v-tab>{{ $t("Citations") }}</v-tab>
              <v-tab>{{ $t("Subjects") }}</v-tab>
              <v-tab>{{ $t("Past Institutions") }}</v-tab>
              <v-tab-item>
                <v-data-table
                  locale="en"
                  item-key="ids.iscopus_id"
                  :headers="[
                    { text: 'Publication', value: 'title' },
                    { text: 'ID', value: 'ids.scopus_id' },
                    { text: 'Date published', value: 'date_published' }
                  ]"
                  :items="record.publications.publication_list"
                  class="elevation-1"
                >
                  <template #item.title="{ item }">
                    <router-link :to="'/scopus-publications/id-' + item.ids.scopus_id">
                      {{ item.title }}
                    </router-link>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item> </v-tab-item>
              <v-tab-item>
                <v-sheet
                  v-for="(domain, ind) in record.subject_areas"
                  :key="'cat' + ind"
                  elevation="3"
                  class="pa-3 mb-3 mt-3"
                >
                  <v-row dense>
                    <v-col cols="3" sm="2" class="text-h3">
                      {{ domain.domain_name }}
                    </v-col>
                    <v-col cols="9" sm="10">
                      <v-row dense>
                        <v-col cols="12">
                          <v-row v-for="(category, i) in domain.categories" :key="'ind' + i" dense>
                            <v-col cols="12" sm="8">
                              {{ category.category_name }}
                            </v-col>
                            <v-col cols="12" sm="4">
                              {{ category.frequency }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-tab-item>
              <v-tab-item>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Departament
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in record.affiliations.institutions" :key="item.id">
                        <td>{{ item.name }}</td>
                        <td>{{ item.departament }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      id: "",
      record: {},
      loading: true,
      path: "",
      render: 1,
      headersP: [
        { text: "Publication", value: "publication.title" },
        { text: "Date published", value: "publication.date_published" }
      ],
      headersE: [
        { text: "Journal", value: "journal.name" },
        { text: "Verified", value: "verified" },
        { text: "Current", value: "current" }
      ],
      headersR: [
        { text: "Publisher", value: "publisher.name" },
        { text: "Verified", value: "verification.verified" },
        { text: "Date reviewed", value: "date_reviewed" }
      ],
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Scopus",
          disabled: false,
          href: "/scopus"
        }
      ]
    }
  },
  computed: {},
  watch: {
    $route(to) {
      this.refreshPage()
    }
  },
  created() {
    this.$log("routec ", this.$route)
    this.refreshPage()
  },
  methods: {
    refreshPage() {
      if (this.$route.name == "ScopusPublisher") {
        this.id = this.$route.params.pathMatch
        this.getRecords()
      }
    },
    getRecords() {
      axios
        .get('infuni_scopus?where={"ids.scopus_id":"' + this.id + '"}')
        .then(response => {
          this.record = response.data._items[0]
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
<style scoped>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
